import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Button, Box } from "@material-ui/core";
import useAuth from "../../lib/useAuth";

const NoSession = () => {
  const { login } = useAuth();

  return (
    <>
      <Route exact path="/">
        <Box>
          <Button variant="outlined" onClick={login}>
            Login with Google
          </Button>
        </Box>
      </Route>
      <Redirect to="/" />
    </>
  );
};

export default NoSession;
