import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Grid, Typography } from "@material-ui/core";
import { useMutation, useQueryClient } from "react-query";

import ITask from "../../types/ITask";
import LDS_COLORS from "../../../ui-common/enums/LdsColors";
import IHill from "../../../ui-hills/types/IHill";
import { NS as TasksNS, tasksDelete } from "../../config/service";

/**
 * Easier way to see the percentage
 * @param percent
 * @returns
 */
function getGreenToRed(percent: number) {
  const r =
    percent < 50 ? 255 : Math.floor(255 - ((percent * 2 - 100) * 255) / 100);
  const g = percent > 50 ? 255 : Math.floor((percent * 2 * 255) / 100);
  return "rgba(" + r + "," + g + ",33, 0.8)";
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
    border: `2px solid ${LDS_COLORS.N400}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
  button: {
    paddingLeft: theme.spacing(3),
  },
}));

interface TasksListProps {
  tasks: ITask[];
  hill: IHill;
  onDelete: () => void;
}

const TasksList: React.FC<TasksListProps> = ({ tasks, hill, onDelete }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  /**
   *  Remove task from list
   */
  const { mutateAsync: removeTaskAsync } = useMutation(
    ["hills", hill.id, "tasks"],
    //@ts-ignore
    async ({ hillId, taskId }: any) => tasksDelete(hillId, taskId),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(["hills", hill.id, "tasks"]);
        onDelete();
      },
      onError: (err: any) =>
        console.log("Server Error: " + JSON.stringify(err)),
    }
  );

  const onRemoveTask = useCallback(
    async ({ hill, task }: { hill: IHill; task: ITask }) => {
      await removeTaskAsync({ hillId: hill.id, taskId: task.id });
    },
    [removeTaskAsync]
  );

  console.log({ hill, tasks });

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Typography></Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Task Title</TableCell>
            <TableCell>Progress</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task) => (
            <TableRow key={task.id}>
              <TableCell style={{ width: "150px" }}>
                {new Date(hill.pubDate).toLocaleDateString()}
              </TableCell>
              <TableCell>{task.description}</TableCell>
              <TableCell
                style={{
                  backgroundColor: getGreenToRed(Math.round(task.x)),
                  width: "80px",
                }}
              >
                {Math.round(task.x)}%
              </TableCell>

              <TableCell align="right" style={{ width: "80px" }}>
                <Button
                  onClick={() => onRemoveTask({ hill, task })}
                  color="secondary"
                  className={classes.button}
                  startIcon={<DeleteIcon />}
                ></Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default TasksList;
