import {
  CircularProgress,
  Grid,
  makeStyles,
  Mark,
  Slider,
  Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  snapshotsAll,
  NS as SnapshotsNS,
} from "../../../ui-snapshots/config/service";
import ISnapshot from "../../../ui-snapshots/types/ISnapshot";
import IHill from "../../types/IHill";

function formatDate(index: number, snapshots: ISnapshot[]) {
  return snapshots[index] ? `${snapshots[index].pubDate}` : "Current";
}

const useStyles = makeStyles((theme) => ({
  slider: {
    width: "90%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface HillTimetravelProps {
  hill: IHill;
  onTimeTravel: (snapshot?: ISnapshot) => void;
}

const HillTimetravel: React.FC<HillTimetravelProps> = ({
  hill,
  onTimeTravel,
}) => {
  const [selectedSnapshotDate, setSelectedSnapshotDate] = useState<number>(0);
  const classes = useStyles();

  const { isLoading, data } = useQuery(
    [...SnapshotsNS, "get", hill.id],
    () => snapshotsAll(hill.id)
  );

  const snapshots = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const dates = snapshots.map((s) => new Date(s.pubDate).getTime()).sort();
  const marks: Mark[] = dates.map((value, index) => ({
    value: index,
    label: new Date(value).toLocaleDateString(),
  }));

  useEffect(() => {
    setSelectedSnapshotDate(snapshots.length);
  }, [snapshots]);

  const onChange = useCallback((event, val) => {
    const value = val as number;
    setSelectedSnapshotDate(value);
  }, []);

  const onChangeCommitted = useCallback(() => {
    const snapshotData = data?.data || [];

    onTimeTravel(snapshotData[selectedSnapshotDate]);
  }, [onTimeTravel, data, selectedSnapshotDate]);

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.slider}
    >
      <Typography id="discrete-slider-small-steps" gutterBottom>
        Selected Date: {formatDate(selectedSnapshotDate, snapshots)}
      </Typography>
      {isLoading && dates.length ? (
        <CircularProgress />
      ) : (
        <Slider
          // defaultValue={snapshots.length-1}
          value={selectedSnapshotDate}
          aria-labelledby="discrete-slider-always"
          step={1}
          marks={marks}
          min={0}
          max={dates.length}
          valueLabelDisplay="auto"
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
        />
      )}
    </Grid>
  );
};

export default HillTimetravel;
