import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";

import { hillsAll } from "../../config/service";
import { tasksAll } from "../../../ui-tasks/config/service";
import HillComponent from "../../components/HillComponent";
import IHill from "../../types/IHill";
import ITask from "../../../ui-tasks/types/ITask";

const HillsContainer: React.FC<{}> = () => {
  const { id: hillId } = useParams<{ id: string }>();

  const hillsQuery = useQuery(["hills"], () => hillsAll(), {
    placeholderData: { data: {} as any },
  });

  const tasksQuery = useQuery(
    ["hills", hillId, "tasks"],
    () => tasksAll(hillId),
    {
      placeholderData: { data: [] as any[] },
    }
  );

  return (
    <Container maxWidth="lg">
      {tasksQuery.isLoading ? (
        <CircularProgress />
      ) : hillsQuery.isSuccess && tasksQuery.isSuccess ? (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <HillComponent
            hill={
              hillsQuery.data?.data.find(({ id }: any) => id === hillId) ?? {}
            }
            tasks={tasksQuery.data?.data ?? []}
          />
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography variant="h4" color="textSecondary">
            Select or create a new Hill.
          </Typography>
        </Grid>
      )}
    </Container>
  );
};

export default HillsContainer;
