import React, { useCallback, useEffect, useRef, useState } from "react";
import { find, propEq } from "ramda";
import { useMutation, useQueryClient } from "react-query";
import debouncePromise from "debounce-promise";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  makeStyles,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import PhotoIcon from "@material-ui/icons/Photo";

import HillChart from "hill-chart";
import "hill-chart/dist/styles.css";

import DeleteHillModal from "../DeleteHillModal";
import CreateTaskModal from "../../../ui-tasks/components/CreateTaskModal";
import ITask from "../../../ui-tasks/types/ITask";
import IHill from "../../types/IHill";
import { NS as TasksNS } from "../../../ui-tasks/config/service";
import {
  NS as SnapshotsNS,
  snapshotsCreate,
  ICreateSnapshot,
} from "../../../ui-snapshots/config/service";
import { downloadImage, getChartConfig } from "../../utils/chartUtil";
import { hillsUpdate, NS as HillsNS } from "../../config/service";
import LDS_COLORS from "../../../ui-common/enums/LdsColors";
import HillTimetravel from "../HillTimetravel";
import ISnapshot from "../../../ui-snapshots/types/ISnapshot";
import TasksContainer from "../../../ui-tasks/containers/TasksContainer";
import mdRenderers from "../../utils/mdRenderers";

interface HillComponentProps {
  hill: IHill;
  tasks: ITask[];
}

const getChartClassName = () => "hill-chart";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    // border: `2px solid ${LDS_COLORS.N400}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    textTransform: "capitalize",
  },
  textarea: {
    width: "90%",
    border: `2px solid ${LDS_COLORS.N300}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: LDS_COLORS.N100,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  slider: {
    width: "90%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const HillComponent: React.FC<HillComponentProps> = ({ tasks, hill }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [hillState, setHillState] = useState<IHill>(hill);
  const [selectedSnapshot, setSelectedSnapshot] = useState<ISnapshot>();
  const [showCreateTask, setCreateTask] = useState(false);
  const [showRemoveHill, setShowRemoveHill] = useState(false);
  const [, setSelectedTask] = useState<ITask>();
  const chartRef = useRef();

  useEffect(() => {
    setHillState(hill);
  }, [hill]);
  /**
   *  Update Hill
   */
  const { mutateAsync: updateHillAsync } = useMutation(hillsUpdate, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["hills", hill.id, "tasks"], {
        exact: false,
      });
    },
    onError: (err: any) => console.log("Server Error: " + JSON.stringify(err)),
  });

  const { mutateAsync: snapshotsCreateAsync } = useMutation(
    [...SnapshotsNS, "post"],
    snapshotsCreate,
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries([...SnapshotsNS], {
          exact: false,
        });
      },
      onError: (err: any) =>
        console.log("Server Error: " + JSON.stringify(err)),
    }
  );

  const persistHill = useCallback(async () => {
    // @ts-ignore
    const tasksFromChart = chartRef?.current?.data || ([] as ITask[]);
    const updatedTasks = tasksFromChart.map((chartTask: ITask) => ({
      ...find(propEq("id", chartTask.id))(tasks),
      ...chartTask,
      // @ts-ignore
      x: Math.round(chartRef.current.xScale.invert(chartTask.x) * 100) / 100,
      // @ts-ignore
      y: Math.round(chartRef.current.yScale.invert(chartTask.y) * 100) / 100,
    }));
    const payload = { hill: hillState, tasks: updatedTasks };

    await updateHillAsync(payload);
  }, [tasks, hillState, updateHillAsync]);

  const updateHillDebounce = debouncePromise(persistHill, 300);
  /**
   *
   */
  useEffect(() => {
    if (selectedSnapshot || tasks) {
      const chartConfig = getChartConfig();
      const chartTasks = selectedSnapshot ? selectedSnapshot.tasks : tasks;

      if (chartRef.current) {
        // @ts-ignore
        chartRef.current.replaceAndUpdate(chartTasks);
      } else {
        const chart = new HillChart(chartTasks, chartConfig);
        chart.on("moved", (task: ITask) => {
          updateHillDebounce();
        });
        chart.on("pointClick", (data: ITask) => {
          setSelectedTask(data);
        });
        chartRef.current = chart;
      }
      // @ts-ignore
      chartRef.current.render();
    }
    // If we add the debounce function here a race condition happens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSnapshot, tasks]);

  const onTaskListChanged = useCallback(async () => {
    await queryClient.invalidateQueries([...HillsNS, ...TasksNS], {
      exact: false,
    });
    setCreateTask(false);
  }, [queryClient]);

  /**
   * Used when deleting a hill
   */
  const onHillDeleted = useCallback(async () => {
    setShowRemoveHill(false);
    history.push("/");
  }, [queryClient]);

  const onDownloadImage = useCallback(() => {
    if (chartRef.current) {
      const chartElement = document.getElementById("hillChartContainer");
      if (chartElement) downloadImage(chartElement);
    }
  }, []);

  /**
   * Persist a hill state for timetravel
   */
  const onSaveHillState = useCallback(async () => {
    const payload: ICreateSnapshot = {
      hillId: hillState.id,
      hillDescription: hillState.description,
      tasks,
    };
    await snapshotsCreateAsync(payload);
  }, [hillState, tasks, snapshotsCreateAsync]);

  const onTimeTravel = useCallback((snapshot?: ISnapshot) => {
    console.log(snapshot);
    setSelectedSnapshot(snapshot);
  }, []);

  const usesSnapshot = useCallback(() => {
    return !!selectedSnapshot;
  }, [selectedSnapshot]);

  return (
    <Container>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid
          id="hillChartContainer"
          container
          spacing={1}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Typography
            gutterBottom
            variant="h5"
            color="textSecondary"
            className={classes.title}
          >
            {hillState && hillState.title}
          </Typography>
          <svg className={getChartClassName()} />
          {usesSnapshot() ? (
            <Box
              width="90%"
              maxHeight="50vh"
              overflow="auto"
              paddingX="2rem"
              marginX="2rem"
              bgcolor={grey[50]}
            >
              <ReactMarkdown skipHtml plugins={[gfm]} components={mdRenderers}>
                {selectedSnapshot?.hillDescription ?? ""}
              </ReactMarkdown>
            </Box>
          ) : (
            <TextareaAutosize
              placeholder="write description here"
              id="hillDescription"
              rowsMin={10}
              rowsMax={50}
              disabled={usesSnapshot()}
              value={hillState.description}
              className={classes.textarea}
              onChange={(event) =>
                setHillState((state: IHill) => ({
                  ...state,
                  description: event.target.value,
                }))
              }
              // Persist on blur
              onBlur={updateHillDebounce}
            />
          )}
        </Grid>
        <HillTimetravel hill={hill} onTimeTravel={onTimeTravel} />
        <TasksContainer
          hill={hill}
          tasks={selectedSnapshot ? selectedSnapshot.tasks : tasks}
          onDelete={() => onTaskListChanged()}
        />
        <Grid container direction="row" justify="center" alignItems="center">
          <ButtonGroup variant="text" aria-label="text primary button group">
            <Button
              onClick={() => setCreateTask(true)}
              color="primary"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
              disabled={usesSnapshot()}
            >
              Add Task
            </Button>
            <Button
              onClick={() => onSaveHillState()}
              color="primary"
              className={classes.button}
              startIcon={<SaveIcon />}
              disabled={usesSnapshot()}
            >
              Create Timestamp
            </Button>
            <Button
              onClick={() => onDownloadImage()}
              color="primary"
              className={classes.button}
              startIcon={<PhotoIcon />}
            >
              Take Snapshot
            </Button>
            <Button
              onClick={() => setShowRemoveHill(true)}
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon />}
            >
              Delete Hill
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <CreateTaskModal
        hill={hill}
        onClose={() => setCreateTask(false)}
        onSuccess={() => onTaskListChanged()}
        open={showCreateTask}
      />
      <DeleteHillModal
        data={hill}
        onClose={() => setShowRemoveHill(false)}
        onSuccess={() => onHillDeleted()}
        open={showRemoveHill}
      />
    </Container>
  );
};

export default HillComponent;
