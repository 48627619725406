import ITask from "../../ui-tasks/types/ITask";
import IHill from "../../ui-hills/types/IHill";
import html2canvas from "html2canvas";

export const getChartData = (hill: IHill, tasksData: ITask[]) => {
  const tasksForHill = tasksData.filter((t) => t.hillId === hill.id);

  return tasksForHill;
};

export const getChartConfig = () => {
  return {
    target: ".hill-chart",
    width: 700,
    height: 270,
    preview: false,
    // darkMode: true, // Whether the dark color scheme is to be used
    // backgroundColor: "transparent", // Color to be used as bg, Use true for default color
    // footerText: {
    //   // control footer text
    //   show: true,
    //   fontSize: 0.75, // in rems
    // },
    // margin: {
    //   // the margins of the inner chart from the svg frame
    //   top: 20,
    //   right: 20,
    //   bottom: 40,
    //   left: 20,
    // },
  };
};

export const downloadImage = (chartElement: HTMLElement) => {
  html2canvas(chartElement).then((canvas: HTMLCanvasElement) => {
    const imgURL = canvas.toDataURL("image/png");

    const dlLink = document.createElement("a");
    dlLink.download = "image";
    dlLink.href = imgURL;
    dlLink.dataset.downloadurl = [
      "image/png",
      dlLink.download,
      dlLink.href,
    ].join(":");

    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  });
};
