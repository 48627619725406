import { Box, Card, CardContent } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const HillCardSkeleton = () => (
  <Card>
    <CardContent>
      <Box mb={2}>
        <Skeleton variant="text" />
      </Box>
      <Box mb={2}>
        <Skeleton variant="rect" height={125} />
      </Box>
      <Box mb={2}>
        <Skeleton variant="rect" width={35} />
      </Box>
    </CardContent>
  </Card>
);

export default HillCardSkeleton;
