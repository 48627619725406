import { AxiosRequestConfig } from "axios";
import { httpInstance } from "../../ui-common/utils/networkUtil";
import ISnapshot from "../types/ISnapshot";

export const NS = ["snapshots"];

export const snapshotsAll = (id: string) => {
  const options: AxiosRequestConfig = {
    method: "get",
    url: "/api/snapshots/all?hillId=" + id,
  };

  return httpInstance<ISnapshot[]>(options);
};

export type ICreateSnapshot = Omit<ISnapshot, "id" | "pubDate">;

export const snapshotsCreate = (data: ICreateSnapshot) => {
  const options: AxiosRequestConfig = {
    method: "post",
    url: "/api/snapshots/create",
    data,
  };

  return httpInstance(options);
};
