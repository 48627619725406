import React, { useCallback, useState } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import clsx from "clsx";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";

import CreateHillModal from "../../../ui-hills/components/CreateHillModal";
import { NS as HillsNS } from "../../../ui-hills/config/service";
import { NS as TasksNS } from "../../../ui-tasks/config/service";
import { useStyles } from "../../utils/stylingUtil";


function Navigation() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [showAddHill, setShowAddHill] = useState(false);

  const onCreateSuccess = useCallback(
    (id: number) => {
      queryClient.invalidateQueries([...HillsNS, ...TasksNS], {
        exact: false,
      });

      history.push(`/hills/${id}`);

      setShowAddHill(false);
    },
    [queryClient, history]
  );

  return (
    <nav>
      <ButtonGroup variant="text" aria-label="text primary button group">
        <Button
          onClick={() => setShowAddHill(true)}
          className={clsx(classes.topButton)}
        >
          Add Hill
        </Button>
      </ButtonGroup>
      <CreateHillModal
        open={showAddHill}
        onSuccess={onCreateSuccess}
        onCancel={() => setShowAddHill(false)}
        onClose={() => setShowAddHill(false)}
      >
        <div></div>
      </CreateHillModal>
    </nav>
  );
}

export default Navigation;
