import { AxiosRequestConfig } from "axios";
import { httpInstance } from "../../ui-common/utils/networkUtil";
import ITask from "../types/ITask";
import { TaskFormValues } from "../types/TaskFormValues";

export const NS = ["tasks"];

export const tasksAll = (hill: string) => {
  const options: AxiosRequestConfig = {
    method: "get",
    url: "/api/tasks/all",
    params: {
      hill,
    },
  };

  return httpInstance<ITask[]>(options);
};

export const tasksCreate = (data: TaskFormValues) => {
  const options: AxiosRequestConfig = {
    method: "post",
    url: "/api/tasks/create",
    data,
  };

  return httpInstance(options);
};

export const tasksUpdate = (data: ITask) => {
  const options: AxiosRequestConfig = {
    method: "put",
    url: "/api/tasks/update",
    data,
  };

  return httpInstance(options);
};

export const tasksDelete = (hillId: string, taskId: ITask["id"]) => {
  const options: AxiosRequestConfig = {
    method: "put",
    url: "/api/tasks/delete",
    data: { hillId, taskId },
  };

  return httpInstance(options);
};

export const tasksReset = () => {
  const options: AxiosRequestConfig = {
    method: "put",
    url: "/api/tasks/reset",
  };

  return httpInstance(options);
};
