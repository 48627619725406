import React from "react";
import { Switch, Route } from "react-router-dom";
import Hills from "../Hills";
import HillContainer from "../../packages/ui-hills/containers/HillsContainer";

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/" children={<Hills />} />
      <Route path="/hills/:id" children={<HillContainer />} />
    </Switch>
  );
};

export default AppRouter;
