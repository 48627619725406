const LDS_COLORS = {
  // Lenses Design System
  // Variable Declaration File

  // Colours

  // Neutrals
  N0: "#FBFDFE",
  N50: "#F6F8FC",
  N75: "#ECF0F8",
  N100: "#E1E7F4",
  N150: "#CED5E4",
  N200: "#AFB8CD",
  N300: "#7C89A8",
  N400: "#64708B",
  N500: "#47536D",
  N600: "#2D3649",
  N700: "#202530",

  // Reds
  R100: "#EF605E",
  R200: "#C54543",

  // Greens
  G100: "#96C543",

  // Blues
  B100: "#5584BA",

  // Yellows
  Y100: "#FFC120",
};

export default LDS_COLORS;
