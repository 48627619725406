import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import styled from "styled-components";
import { useHistory } from "react-router";

import IHill from "../../types/IHill";
import { hillsAll, NS } from "../../config/service";

const StyledContainer = styled.div`
  width: 300px;
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  margin-left: 30px;
  margin-right: 30px;
`;

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "blue",
    padding: 20,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

interface ISelectOption {
  value: string;
  label: string;
}

const HillSelect: React.FC<{}> = ({ children }) => {
  const history = useHistory();

  const [options, setOptions] = useState<ISelectOption[]>();
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    null
  );

  const { data } = useQuery([...NS, "get"], () => hillsAll());

  const handleChange = useCallback(
    (option: ISelectOption | null) => {
      setSelectedOption(option);

      if (option) history.push(`/hills/${option.value}`);
    },
    [history]
  );

  useEffect(() => {
    const hillsData = (data && data.data) || [];

    const opts = hillsData.map((hill: IHill) => ({
      value: hill.id,
      label: hill.title,
    }));

    setOptions(opts);
    // if (opts && opts.length) handleChange(opts[opts.length-1]);
  }, [data, handleChange]);

  return (
    <StyledContainer>
      <Select
        placeholder="Select Hill..."
        name="hillsList"
        value={selectedOption}
        onChange={(option) => handleChange(option)}
        options={options}
        styles={customStyles}
        isSearchable={true}
      />
    </StyledContainer>
  );
};

export default HillSelect;
