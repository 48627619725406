import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
//   Button,
//   CardActions,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  pos: {
    marginBottom: 12,
  },
}));

interface TransitionsModalProps extends ModalProps {
  title?: string;
}

export default function TransitionsModal({
  title = "",
  open,
  onClose,
  children,
}: TransitionsModalProps) {
  const classes = useStyles();

  //   const bull = <span className={classes.bullet}>•</span>;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardContent>
            <Typography
              id="transition-modal-description"
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {title}
            </Typography>
            {/* <Typography variant="h5" component="h2">
              {title}
            </Typography> */}
            {children}
          </CardContent>
          {/* <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions> */}
        </Card>
      </Fade>
    </Modal>
  );
}
