import React from "react";
import { Container, makeStyles } from "@material-ui/core";

import TasksList from "../../components/TasksList";
import IHill from "../../../ui-hills/types/IHill";
import ITask from "../../types/ITask";

interface TasksContainerProps {
  hill: IHill;
  tasks: ITask[];
  onDelete: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
}));

const TasksContainer: React.FC<TasksContainerProps> = ({
  hill,
  tasks,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      {tasks && tasks.length ? (
        <TasksList tasks={tasks} hill={hill} onDelete={onDelete} />
      ) : (
        <div>No tasks available for this hill</div>
      )}
    </Container>
  );
};

export default TasksContainer;
