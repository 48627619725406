import axios, { AxiosRequestConfig } from "axios";
import useAuth from "../../../lib/useAuth";

export const http = axios.create({});

http.interceptors.request.use((req) => {
  req.headers.authorization = `Bearer ${useAuth.getState().token}`;

  return req;
});

http.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      useAuth.getState().logout();
    }

    return Promise.reject(error);
  }
);

export const httpInstance = <T = any>(options: AxiosRequestConfig) => {
  return http.request<T>({
    ...options,
  });
};
