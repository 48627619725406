// import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import useAuth from "./lib/useAuth";
import AppShell from "./containers/AppShell";
import NoSession from "./containers/NoSession";
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const AppComponent = useAuth(({ user }) => (user ? AppShell : NoSession));

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppComponent />
      </Router>
    </QueryClientProvider>
  );
};

export default App;
