import { AxiosRequestConfig } from "axios";
import { httpInstance } from "../../ui-common/utils/networkUtil";
import ITask from "../../ui-tasks/types/ITask";
import { HillFormValues } from "../types/HillFormValues";
import IHill from "../types/IHill";

export const NS = ["hills"];

export const hillsAll = () => {
  const options: AxiosRequestConfig = {
    method: "get",
    url: "/api/hills/all",
  };

  return httpInstance<IHill[]>(options);
};

interface ICreateHillResponse {
  message: string;
  id: number;
}

export const hillsCreate = (data: HillFormValues) => {
  const options: AxiosRequestConfig = {
    method: "post",
    url: "/api/hills/create",
    data,
  };

  return httpInstance<ICreateHillResponse>(options);
};

export const hillsDelete = (id: string) => {
  const options: AxiosRequestConfig = {
    method: "put",
    url: "/api/hills/delete",
    data: { id },
  };

  return httpInstance(options);
};

export const hillsUpdate = (data: { hill: IHill; tasks: ITask[] }) => {
  const options: AxiosRequestConfig = {
    method: "put",
    url: "/api/hills/update",
    data,
  };

  return httpInstance(options);
};
