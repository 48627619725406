import { Button, ButtonGroup, Grid, ModalProps } from "@material-ui/core";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import TransitionsModal from "../../../ui-common/components/TransitionsModal";
import { hillsDelete, NS } from "../../config/service";
import IHill from "../../types/IHill";

interface DeleteHillComponentProps extends Omit<ModalProps, "children"> {
  data: IHill;
  onSuccess: () => void;
}

function DeleteHillModal({
  onSuccess,
  onClose,
  open,
  data,
}: DeleteHillComponentProps) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(hillsDelete, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["hills"], {
        exact: false,
      });
      onSuccess();
    },
    onError: (err: any) => console.log("Server Error: " + JSON.stringify(err)),
  });

  const removeHill = useCallback(
    async (data: IHill) => {
      await mutateAsync(data.id.toString());
    },
    [mutateAsync]
  );

  return (
    <TransitionsModal
      title="Delete Hill"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Grid>
        <blockquote>
          Once a hill is removed, you will not be able to retrieve it anymore.
          Are you sure you want to proceed?
        </blockquote>
        <ButtonGroup>
          <Button onClick={() => removeHill(data)}>Ok</Button>
          <Button onClick={onSuccess}>Cancel</Button>
        </ButtonGroup>
      </Grid>
    </TransitionsModal>
  );
}

export default DeleteHillModal;
