import {
  Box,
  Button,
  ButtonGroup,
  ModalProps,
  TextField,
  Typography,
} from "@material-ui/core";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import ColorPicker from "../../../ui-common/components/ColorPicker";
import TransitionsModal from "../../../ui-common/components/TransitionsModal";
import IHill from "../../../ui-hills/types/IHill";
import { tasksCreate, NS as TasksNS } from "../../config/service";
import { TaskFormValues } from "../../types/TaskFormValues";

interface CreateTaskModalProps extends Omit<ModalProps, "children"> {
  hill: IHill;
  onSuccess: () => void;
}

function CreateTaskModal({
  onSuccess,
  onClose,
  open,
  hill,
}: CreateTaskModalProps) {
  // const classes = useStyles();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(tasksCreate, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["hills", hill.id, "tasks"]);
      onSuccess();
    },
    onError: (err: any) => console.log("Server Error: " + JSON.stringify(err)),
  });

  const handleSubmit = useCallback(
    async (values: TaskFormValues) => {
      await mutateAsync(values);
    },
    [mutateAsync]
  );

  return (
    <div>
      {hill ? (
        <TransitionsModal open={open} onClose={onClose} title="Add new task">
          <Formik
            initialValues={{
              hillId: hill.id,
              description: "",
              x: 0,
              y: 0,
              color: "red",
              size: 10,
            }}
            // validate={validate}
            // validateOnBlur
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
            }}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
            }: FormikProps<TaskFormValues>) => (
              <Form onChange={handleChange} onSubmit={handleSubmit}>
                <Field name="description" required>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <TextField
                        fullWidth
                        {...field}
                        id="description"
                        label="Name"
                        type="textarea"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                      />
                    </>
                  )}
                </Field>
                <Field name="size" required>
                  {({ field, meta }: FieldProps) => (
                    <>
                      <TextField
                        fullWidth
                        required
                        type="numerical"
                        {...field}
                        id="size"
                        label="size"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                      />
                    </>
                  )}
                </Field>
                <Field name="color" required>
                  {({ field }: FieldProps) => (
                    <>
                      <Typography
                        gutterBottom
                        variant="caption"
                        color="textSecondary"
                      >
                        Color Select
                      </Typography>
                      <ColorPicker
                        color={field.value}
                        onChangeComplete={(value) => {
                          setFieldValue(field.name, value);
                        }}
                      />
                    </>
                  )}
                </Field>
                <Box mt={3} alignItems="pullRight">
                  <ButtonGroup
                    variant="text"
                    color="primary"
                    aria-label="text primary button group"
                  >
                    <Button type="submit">Submit</Button>
                    <Button color="secondary" onClick={() => onSuccess()}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Box>
              </Form>
            )}
          </Formik>
        </TransitionsModal>
      ) : null}
    </div>
  );
}

export default CreateTaskModal;
