import axios from "axios";
import hello from "hellojs";
import createState from "zustand";

axios.get("/api/configuration").then((response) => {
  const {
    data: { googleClientId: google },
  } = response;

  hello.init({
    google,
    response_type: "code",
  });
});

export type State = {
  user?: any;
  token?: string;
  login: () => void;
  logout: () => void;
};

const useAuth = createState<State>(() => ({
  user: undefined,
  token: undefined,
  login: () =>
    hello("google").login({ display: "popup", scope: "profile email" }),
  logout: () => hello("google").logout(),
}));

hello.on("auth.login", async (auth) => {
  const { access_token } = hello(auth.network).getAuthResponse();

  const user = await hello(auth.network).api("me");

  useAuth.setState({
    user,
    token: access_token,
  });
});

hello.on("auth.logout", () => {
  useAuth.setState({ user: undefined });
});

export default useAuth;
