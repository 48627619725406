import React, { useCallback } from "react";
import { useMutation } from "react-query";
import {
  Box,
  Button,
  ButtonGroup,
  ModalProps,
  TextField,
} from "@material-ui/core";
import { Formik, Form, FormikProps, Field, FieldProps } from "formik";
import { hillsCreate, NS as HillsNS } from "../../config/service";
import { HillFormValues } from "../../types/HillFormValues";
import TransitionsModal from "../../../ui-common/components/TransitionsModal";

interface CreateHillModalProps extends ModalProps {
  onSuccess: (id: number) => void;
  onCancel: () => void;
}

function CreateHillModal({ onSuccess, onCancel, open }: CreateHillModalProps) {
  const { mutateAsync } = useMutation([...HillsNS, "create"], hillsCreate, {
    retry: false,
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response.data.id);
    },
    onError: (err: any) => console.log("Server Error: " + JSON.stringify(err)),
  });

  const handleSubmit = useCallback(
    async (values: HillFormValues) => {
      await mutateAsync(values);
    },
    [mutateAsync]
  );

  return (
    <TransitionsModal
      title="Create Hill"
      open={open}
      onClose={onCancel}
      closeAfterTransition
    >
      <Formik
        initialValues={{
          description: "",
          author: "",
          title: "",
        }}
        // validate={validate}
        // validateOnBlur
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        }: FormikProps<HillFormValues>) => (
          <Form onChange={handleChange} onSubmit={handleSubmit}>
            <Field name="author" required>
              {({ field, meta }: FieldProps) => (
                <>
                  <TextField
                    fullWidth
                    required
                    {...field}
                    id="author"
                    label="Author"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                </>
              )}
            </Field>
            <Field name="title" required>
              {({ field, meta }: FieldProps) => (
                <>
                  <TextField
                    fullWidth
                    required
                    {...field}
                    id="title"
                    label="title"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                </>
              )}
            </Field>
            <Field name="description" required>
              {({ field, meta }: FieldProps) => (
                <>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    {...field}
                    id="description"
                    label="description"
                    type="textarea"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                </>
              )}
            </Field>
            <Box mt={3} alignItems="pullRight">
              <ButtonGroup
                variant="text"
                color="primary"
                aria-label="text primary button group"
              >
                <Button type="submit">Submit</Button>
                <Button color="secondary" onClick={() => onCancel()}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Box>
          </Form>
        )}
      </Formik>
    </TransitionsModal>
  );
}

export default CreateHillModal;
