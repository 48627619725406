import { Grid, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { http } from "../../packages/ui-common/utils/networkUtil";
import HillCard from "./HillCard";
import HillCardSkeleton from "./HillCardSkeleton";

export default () => {
  const { data, isFetched, isLoading } = useQuery(["hills"], () =>
    http.get<any, any>("/api/hills/all")
  );

  const history = useHistory();

  return (
    <div>
      <Typography gutterBottom component="h1" variant="h6">
        Hills
      </Typography>
      {isLoading && (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} md={4}>
            <HillCardSkeleton />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <HillCardSkeleton />
          </Grid>
        </Grid>
      )}
      {isFetched && (
        <Grid container spacing={6}>
          {data.data.map((hill: any) => (
            <Grid item xs={12} sm={6} md={4} key={hill.id}>
              <HillCard
                title={hill.title}
                description={hill.description}
                published={hill.pubDate}
                onOpen={() => history.push(`/hills/${hill.id}`)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};
