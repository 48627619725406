import {
  Box,
  Button,
  Card,
  CardProps,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import * as R from "ramda";

export type Props = CardProps & {
  onOpen: () => void;
  title: string;
  published: string;
  description?: string;
};

export default ({
  onOpen,
  title,
  published,
  description = "",
  ...props
}: Props) => {
  const safeDescription = R.isEmpty(description)
    ? "No description provided."
    : description;

  return (
    <Card {...props}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="span">
            {title}
          </Typography>
          <IconButton color="inherit">
            <MoreVert />
          </IconButton>
        </Box>
        <Box mb={2}>
          <Typography variant="caption">Created at {published}</Typography>
        </Box>
        <Box height="2.8em" overflow="hidden">
          <Typography variant="body2">{safeDescription}</Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onOpen}>
          Open
        </Button>
      </CardActions>
    </Card>
  );
};
